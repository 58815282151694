import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
import { withRouter } from 'react-router-dom'
import SendingData from '../SendingData';
import CreateFscDataService from '../../services/data/createfsc.service'
import ProduitDataService from '../../services/data/produit.service';
import 'react-tabulator/lib/css/tabulator.css';
import LoadingData from '../LoadingData';
import BackToMenu from '../BackToMenu';

/* FSC */ 
const fsc = [                  
    {title:"Identifiant BrightLoop", field:"id_brightloop",  width:250, editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},
    {title:"Révision FSC",           field:"rev_fsc",        width:130, editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},
    {title:"NCA",                    field:"rev_nca",        width:95,  editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},
    {title:"PDT",                    field:"rev_pdt",        width:95,  editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},  
];

var fsc_data = [
    {
        id_brightloop: "BLV",
        rev_fsc:"01",
        rev_nca:"01",
        rev_pdt:"01"
    },
];

/* IDENTIFICATION FSC */ 
const identification_fsc = [
    {
        formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell){
            if(window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) cell.getRow().delete()
        }
    },                       
    {title:"Identification",  field:"identifiant_brightloop", width:250, editor:"select", editor:true, hozAlign:"center", headerHozAlign:"center"},
];

var identification_data = [
    {
        identifiant_brightloop: "Exmple ID",
    },
];

/* MODELE FSC */
const type_controle = ["nOK/OK", "Vmin/Vmax", "Vm + %", "État"] 
const modele_fsc = [
    {
        formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell){
            if(window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) cell.getRow().delete()
        }
    },
    {title:"Type §",        field:"type_para",  width:95,   editor:"select", editorParams:{values:["NCA", "PDT"]}, hozAlign:"center", headerHozAlign:"center"},
    {title:"Numéro §",      field:"n_para",     width:110,  editor:"input",  editor:true, headerHozAlign:"center"},
    {title:"Contrôle",      field:"controle",   width:150,  editor:"input",  editor:true, headerHozAlign:"center", hozAlign:"center",
    editorParams:{
        elementAttributes:
        {
            maxlength:"40", //set the maximum character length of the input element
        } 
    }
    },
    {title:"Type contrôle", field:"type_controle",  width:150,  editor:"select", editorParams:{values:type_controle}, hozAlign:"center", headerHozAlign:"center",
        cellEdited:function(cell){
            //const titleColumn = cell.getValue();
            //modele_fsc.addColumn({title:titleColumn, field:"age"}, true, "name");
        },
    },
    {title:"Valeur contrôle", field:"valeur_controle", width:150,  editor:"input", editor:true, headerHozAlign:"center", hozAlign:"center"},
];

  var modele_data = [
    {type_para:"NCA", n_para:"1", controle:"Exemple1", valeur_controle:" "},
    {type_para:"NCA", n_para:"2", controle:"Exemple2", valeur_controle:" "},
    {type_para:"NCA", n_para:"3", controle:"Exemple3", valeur_controle:" "},
    {type_para:"PDT", n_para:"4", controle:"Exemple4", valeur_controle:" "},
    {type_para:"PDT", n_para:"5", controle:"Exemple5", valeur_controle:" "},
    {type_para:"PDT", n_para:"6", controle:"Exemple6", valeur_controle:" "}
];

class CreateFSC extends Component {

    constructor(){
        super();

        this.state = {
            produit: '',
            productInfos: '',
            identifiant_brightloop: '',
            type_item:'',
            fsc_data:[],
            SendingFSC: '',
            LoadingInfos: true
        };

        this.modele = React.createRef();
        this.fsc = React.createRef();
        this.identification = React.createRef();

        this.handleClick= this.handleClick.bind(this);

        this.AddRowModele= this.AddRowModele.bind(this);
        this.AddRowIdentification= this.AddRowIdentification.bind(this);
    }

    componentDidMount() {
        this.setState({
            produit: this.props.match.params.produit,
            SendingFSC: false,
        }, function () {
                console.log("ID Produit (id_item) : ", this.state.produit);
            });

        ProduitDataService.getProductInfos(this.props.match.params.produit)
        .then(response => {
            this.setState({
                productInfos: response.data,
                LoadingInfos: false
            }, function () {
                console.log("[Infos Produits] Données reçues : ", response.data);
                if (response.data[0].type_item == 1) {
                    this.setState({
                        type_item: "BLV",
                        identifiant_brightloop: "BLV"+'_'+response.data[0].num_item+'_'+response.data[0].rev_item
                    }, function () {
                        this.setState({
                            fsc_data:[
                                {
                                    id_brightloop: this.state.identifiant_brightloop,
                                    rev_fsc:"00",
                                    rev_nca:"00",
                                    rev_pdt:"00"
                                }
                            ]
                        });
                       });
                } else if (response.data[0].type_item == 2) {
                    this.setState({
                        type_item: "ENS",
                        identifiant_brightloop: "ENS"+'_'+response.data[0].num_item+'_'+response.data[0].rev_item
                    }, function () {
                        this.setState({
                            fsc_data:[
                                {
                                    id_brightloop: this.state.identifiant_brightloop,
                                    rev_fsc:"00",
                                    rev_nca:"00",
                                    rev_pdt:"00"
                                }
                            ]
                        });
                    });
                } else if (response.data[0].type_item == 3) {
                    this.setState({
                        type_item: "CAR",
                        identifiant_brightloop: "CAR"+'_'+response.data[0].num_item+'_'+response.data[0].rev_item
                    }, function () {
                        this.setState({
                            fsc_data:[
                                {
                                    id_brightloop: this.state.identifiant_brightloop,
                                    rev_fsc:"00",
                                    rev_nca:"00",
                                    rev_pdt:"00"
                                }
                            ]
                        });
                    });
                } else if (response.data[0].type_item == 4) {
                    this.setState({
                        type_item: "SOF",
                        identifiant_brightloop: "SOF"+'_'+response.data[0].num_item+'_'+response.data[0].rev_item
                    }, function () {
                        this.setState({
                            fsc_data:[
                                {
                                    id_brightloop: this.state.identifiant_brightloop,
                                    rev_fsc:"00",
                                    rev_nca:"00",
                                    rev_pdt:"00"
                                }
                            ]
                        });
                    });
                } else if (response.data[0].type_item == 5) {
                    this.setState({
                        type_item: "PLD",
                        identifiant_brightloop: "PLD"+'_'+response.data[0].num_item+'_'+response.data[0].rev_item
                    }, function () {
                        this.setState({
                            fsc_data:[
                                {
                                    id_brightloop: this.state.identifiant_brightloop,
                                    rev_fsc:"00",
                                    rev_nca:"00",
                                    rev_pdt:"00"
                                }
                            ]
                        });
                    });
                } else {
                    this.setState({
                        type_item: "PRO",
                        identifiant_brightloop: "PRO"+'_'+response.data[0].num_item+'_'+response.data[0].rev_item
                    }, function () {
                        this.setState({
                            fsc_data:[
                                {
                                    id_brightloop: this.state.identifiant_brightloop,
                                    rev_fsc:"00",
                                    rev_nca:"00",
                                    rev_pdt:"00"
                                }
                            ]
                        });
                    });
                }
            });
        })
    }
    
    async CreateModele() {

        console.log("[MODELES] Création d'un modèle...")

        const prom = CreateFscDataService.createModele()
        
        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateModele terminé !'
    }

    async CreateFSC() {

        var data_fsc = this.fsc.current.table.getData();
        var id_item = this.state.produit;

        console.log("[FSC] Données envoyées au serveur : ", data_fsc,", ",id_item)

        const prom = CreateFscDataService.createFSC(id_item, data_fsc)
        
        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateFSC terminé !' 
    }

    async CreateModeleIdentification() {

        var data_identification = this.identification.current.table.getData();

        console.log("[MODELE_IDENTIFICATION] Données envoyées au serveur : ", data_identification)

        const prom = CreateFscDataService.createModeleIdentification(data_identification)

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateModeleIdentification terminé !'
    }

    async CreateModeleMesure() {

        var data_modele = this.modele.current.table.getData();

        console.log("[MODELE_MESURE] Données envoyées au serveur : ", data_modele)

        const prom = CreateFscDataService.createModeleMesure(data_modele).then(res => {
            this.setState({ 
                SendingFSC: false 
            }, 
            () => console.log("SendingFSC: ", this.state.SendingFSC),
            this.props.history.push('/fsc/menu_fsc/id_item=' + this.state.produit)                   
            ) 
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateModeleMesure terminé !'
    }


    //Fonctions identification
    AddRowIdentification() {
        this.identification.current.table.addRow(false); //true adds to top
    }
    UndoIdentification() {
        this.identification.current.table.undo();
    }
    RedoIdentification() {
        this.identification.current.table.redo();
    }

    //Fonctions modele
    AddRowModele() {
        this.modele.current.table.addRow(false);
    }
    UndoModele() {
        this.modele.current.table.undo();
    }
    RedoModele() {
        this.modele.current.table.redo();
    }

    handleClick(event){
        event.preventDefault();
        this.setState({ 
            SendingFSC: true, 
        }, 
        () => console.log("Sending FSC: ", this.state.SendingFSC),
        this.CreateModele().then(
            exec => this.CreateFSC().then(
            exec => this.CreateModeleIdentification().then(
            exec => this.CreateModeleMesure()
            ))
        ),
        )
    }

    renderDataSent() {
        if(this.state.SendingFSC) {
          return (
            <div>
                <SendingData/>
            </div>
          );
        }
    }

    render() {
        return (
        !this.state.LoadingInfos
        ?
        <div>
            {this.renderDataSent()}
            
            <BackToMenu/>

            <div className="Titre">
                <h1>Créer une FSC</h1>
            </div>
            
            <div className="Infos"> 
                <h2>                 
                {Object
                    .keys(this.state.productInfos)
                    .map((item, i) => 
                    <p key={i} value={this.state.productInfos[item].name_item}>  
                        <strong>
                            {this.state.type_item}_
                            {this.state.productInfos[item].num_item}_
                            {this.state.productInfos[item].rev_item}_
                            {this.state.productInfos[item].name_item}
                        </strong>
                    </p>)
                } 
                </h2>
                <h3> 
                {Object
                    .keys(this.state.productInfos)
                    .map((item, i) => 
                    <p key={i} value={this.state.productInfos[item].name_item}>
                        {this.state.productInfos[item].com_item}</p>)
                }  
                </h3>
                <h3> ID Produit : {this.state.produit}</h3>
            </div>

            <div className="control">
                <button name="create"  onClick={this.handleClick} className="create_fsc_button" >Créer modèle FSC</button>
            </div>  

            <div className="Table">
                <ReactTabulator
                ref={this.fsc}
                columns={fsc}
                data={this.state.fsc_data}
                tooltips={true}
                layout={"fitColumns"}
                history={true}               
                resizableRows={false}               
                headerSort={false}
                headerHozAlign={"center"}
                />
            </div>

            <div className="control">
                <button name="add_row" onClick={this.AddRowIdentification} className="control_button"    >Add row</button>
            </div>  
            <div className="Table">
                <ReactTabulator
                ref={this.identification}
                columns={identification_fsc}
                data={identification_data}
                tooltips={true}
                layout={"fitColumns"}
                history={true}               //allow undo and redo actions on the table
                resizableRows={false}        //allow row order to be changed       
                headerSort={false}
                headerHozAlign={"center"}
                />
            </div>

            <div className="control">
                <button name="add_row" onClick={this.AddRowModele} className="control_button" >Add row</button>
            </div>  
            <div className="Table">
                <ReactTabulator
                ref={this.modele}
                columns={modele_fsc}
                data={modele_data}
                tooltips={true}
                layout={"fitData"}
                history={true}               
                resizableRows={false}              
                headerSort={false}
                headerHozAlign={"center"}
                />
            </div>
        </div>
        :
        <div>
            <LoadingData/>
        </div>
        );
    }
}

export default withRouter(CreateFSC);