import React, {useEffect, useState} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import FscDataService from '../../services/data/fsc.service';

const ChooseFSC = (props) => {
    const history = useHistory();

    const [ListId, setListId] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const { produit } = useParams(); //produit = id_item

    function getChoixId(e) {
        setSelectedId(e.target.value)
    }

    useEffect(()=>{
        FscDataService.getFSCbyItem(produit)
        .then(response => {
            //console.log("[FSC] Données reçues : ", response.data)
            setListId(response.data)
            if (response.data.length == 0) {
                alert('Aucune FSC disponible pour ce produit !')
                history.push('/fsc/menu_fsc/id_item=' + produit)                   
            }
        })
    }, [])

    function handleSubmit(e) {
        e.preventDefault()
        //alert('FSC ID selected: ' + selectedId)
        if (selectedId <= null) {
            alert("Veuillez sélectionner une FSC correcte !")
        } else {
            props.SelectFSC(selectedId)
        }
    }

    return (
        <div  className="double-dropdown">
            <div style={{float: 'left'}}>                
                <select className="choixtype" id="choix_fsc" onChange={getChoixId}  style={{marginTop:'33px'}}>
                    <option value="">-- Choix de la FSC --</option>
                    {Object
                    .keys(ListId)
                    .map((item, i) => 
                    <option key={i} value={ListId[item].id}
                    >{ListId[item].id_brightloop}_FSC_{ListId[item].rev_fsc}</option>)
                    }       
                </select> 
            </div>

            <div style={{float: 'left'}}>                
                <form method="get" name="envoi" onSubmit={handleSubmit}>
                    <button type="submit" className="button"> Charger la FSC </button>
                </form>
            </div>
        </div>
   );
};

export default withRouter(ChooseFSC);