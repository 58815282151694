import http from "../../http-common";

class FscDataService {

    getFSCbyItem(id_item){
        return http.get('/fscs/getByItem/' + id_item)
    }

    GetIdFSC(id_modele) { 
        return http.get('/fscs/getId/' + id_modele)
    }

    LoadDatecode(id_datecode) { 
        return http.get('/datecodes/getById/' + id_datecode)
    }

    LoadFSC(id_fsc) { 
        return http.get('/fscs/getById/' + id_fsc)
    }

    LoadModeleMesure(id_modele) { 
        return http.get('/load_modele_mesure/' + id_modele)
    }

    LoadModeleIdentification(id_modele) { 
        return http.get('/load_modele_identification/' + id_modele)
    }

    SelectIDModele(id_fsc){
        return http.get('/select_id_modele/' + id_fsc)
    }
}

export default new FscDataService();