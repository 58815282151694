import React from 'react';
import Navbar from '../components/Navbar';

const about = () => {
    return (
        <div>
            <Navbar/>
            <div className="about">
                <p className="about-info"><b>Version :</b> PIM v2.0 <br></br></p>
                <p className="about-info"><b>Date :</b> 10/05/2021</p>
            </div>
        </div>
    );
};

export default about;

