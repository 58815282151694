import React from 'react';
import SelectFscRevise from '../../components/FSC/SelectFscRevise';
import Navbar from '../../components/Navbar';

const select_fsc_revise = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <SelectFscRevise/>
        </div>
    </div>
    );
};

export default select_fsc_revise;