import React from 'react';
import { RingLoader } from 'react-spinners';

class SendingData extends React.Component {
    render() {
        return(
            <div className="sending-data">
                <div className="sendingdata-text">
                    <p>Sending Data...</p>
                </div>

                <div className="sendingdata-icon">
                    <RingLoader/>
                </div>
            </div>
        );
    };
};

export default SendingData;

