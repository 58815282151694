import http from "../../http-common";

class SuiviDataService {
    
    LoadDatecodeByDatecodeId(id_datecode){
        return http.get('/datecodes/getById/' + id_datecode)
    }
    LoadSuiviByDatecodeId(id_datecode){
        return http.get('/load_suivi_by_id_datecode/' + id_datecode)
    }

    UpdateSuiviFSC(data_suivi, id_datecode){
        return http.put('/create_or_update_suivi/' + id_datecode, data_suivi)
        .then(function (response) {
            console.log(response.data);
            alert("Suivi mis à jour !")
        })
        .catch(function (error) {
            console.log(error.response.data);
            alert("Erreur dans le suivi ! Vérfiez que tous les champs sont corrects/remplis !")
        });
    }
}

export default new SuiviDataService();