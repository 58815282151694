import React from 'react';
import CreateMeasure from '../../components/FSC/CreateMeasure';
import Navbar from '../../components/Navbar';

const create_measure = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <CreateMeasure/>
        </div>
    </div>
    );
};

export default create_measure;