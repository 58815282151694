import http from "../../http-common";

class ConsultFscDataService {

    getSaisiesbyDatecode(id_datecode){
        return http.get('/get_SaisiesId_by_datecode/' + id_datecode)
    }
    getIdentificationbyDatecode(id_datecode){
        return http.get('/load_identification_by_datecode/' + id_datecode)
    }

    getModeleMesureId(id_saisie){
        return http.get('/select_id_modele_mesure_by_id_saisie/' + id_saisie)
    }

    getModeleIdentificationId(id_saisie){
        return http.get('/select_id_modele_identification_by_id_saisie/' + id_saisie)
    }


    LoadFSCByModeleId(id_modele){
        return http.get('/fscs/load_fsc_by_modele_id/' + id_modele)
    }

    LoadMesure(id_saisie){
        return http.get('/load_mesure/'+ id_saisie)
    }

    LoadIdentification(id_saisie){
        return http.get('/load_identification/' + id_saisie)
    }

    LoadSuiviByDatecodeId(id_datecode){
        return http.get('/load_suivi_by_id_datecode/' + id_datecode)
    }
}

export default new ConsultFscDataService();