import http from "../../http-common";

class ProduitDataService {

    getByType(type) { 
        return http.get('/produits/' + type)
    }

    getProductInfos(id_item) { 
        return http.get('/infosProduits/' + id_item)
    }
}

export default new ProduitDataService();