import React from 'react';
import Navbar from '../../components/Navbar';
import DoubleDropdown from '../../components/DoubleDropdown';

const fsc = () => {
    return (
        <div>
            <Navbar/>
            <DoubleDropdown/>
        </div>
    );
};

export default fsc;