import React from 'react';
import { RingLoader } from 'react-spinners';

class LoadingData extends React.Component {
    render() {
        return(
            <div>
                <div className="loading-text">
                    <p>Loading Data...</p>
                </div>

                <div className="loading-icon">
                    <RingLoader/>
                </div>

            </div>
        );
    };
};

export default LoadingData;

