import React from 'react';
import { Redirect } from 'react-router';


class LoginForm extends React.Component {
    render() {

        // if(this.state.isLoggedIn === true){
        //     return (<Redirect to="/accueil" />);
        // }else{
        //     return (<div>Login Please</div>);
        // }

        return(
            <div className="login-form">
                <h1>BrightLoop PIM Login</h1>
                <img src="/img/logo-bl.png" alt="logo"/>
                <form 
                //onSubmit={}
                method="post" 
                noValidate>
                    <div className="field">
                        <div className="login">
                            <input
                            autoComplete="off"
                            type="text"
                            name="login"
                            placeholder="Votre login (trigramme)"
                            maxLength="3"
                            minlength="3"
                            //onChange={}
                            //value={values.email || ""}
                            required
                            />
                        </div>
                    </div>

                    <div className="field">
                        <div className="login">
                            <input
                                type="password"
                                name="password"
                                placeholder="Votre mot de passe"
                                //onChange={}
                                //value={values.password || ""}
                                required
                            />
                        </div>
                    </div>
                    <div className="login-button">
                        <button type="submit" className="button"> Se connecter </button>
                    </div>
                </form>
            </div>
            
        );
    };
};

export default LoginForm;