import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
import { withRouter } from 'react-router-dom'
import LoadingData from '../LoadingData';
import SendingData from '../SendingData';
import SuiviDataService from '../../services/data/suivifsc.service';
import 'react-tabulator/lib/css/tabulator.css';
import BackToMenu from '../BackToMenu';

    /* SUIVI FSC */ 
    const suivi_fsc = [
        // {
        //     formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell)
        //     {
        //         if(window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
        //             cell.getRow().delete()
        //         }
        //     }
        // },
        {title:"Commentaire",     field:"commentaire",  width:765,  editor:"input",  editor:true, headerHozAlign:"center"},
        {title:"Signature",       field:"signature",    width:150,  editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},
    ];

class SuiviFSC extends Component {

    constructor(){
        super();
        this.state = {
            id_item: '',
            id_datecode: '',
            datecode: '',
            fsc_suivi_data:[],
            LoadingDatecode: '',
            LoadingSuivi: '',
            SendingSuivi: '',
        };

        this.suivi = React.createRef();

        this.handleClick = this.handleClick.bind(this);
        this.AddRowCommentaire= this.AddRowCommentaire.bind(this);
    }

    componentDidMount() {
        this.setState({
            LoadingDatecode: true,
            LoadingSuivi: true,
            id_item: this.props.match.params.produit,
            id_datecode: this.props.match.params.datecode
        },  function () {
                console.log("ID item : ", this.state.id_item)
                console.log("ID datecode : ", this.state.id_datecode)
                this.LoadDatecode()
                this.LoadSuivi()
            });
    }
    
    AddRowCommentaire() {
        this.suivi.current.table.addRow(false);
    }

    async LoadDatecode(){    
        const prom = SuiviDataService.LoadDatecodeByDatecodeId(this.state.id_datecode).then(res => {
            this.setState({ 
                datecode: res.data[0].datecode,
            }, 
            () => console.log("[DATECODE] Données reçues: ", this.state.datecode),
                this.setState({LoadingDatecode: false}
                )                   
            )        
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadSuivi terminé !'
    } 

    async LoadSuivi(){    
        const prom = SuiviDataService.LoadSuiviByDatecodeId(this.state.id_datecode).then(res => {
            this.setState({ 
                fsc_suivi_data: res.data,
            }, 
            () => console.log("[SUIVI] Données reçues: ", this.state.fsc_suivi_data),
                this.setState({LoadingSuivi: false}
                )                   
            )        
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadSuivi terminé !'
    } 

    async UpdateSuivi(){    
        var data_suivi = this.suivi.current.table.getData();
        console.log("[SUIVI] Données envoyées au serveur : ", data_suivi, ", ",this.state.id_datecode)
        const prom = SuiviDataService.UpdateSuiviFSC(data_suivi, this.state.id_datecode).then(res => {
            this.setState({ 
                SendingSuivi: false 
            },
            () => this.LoadSuivi(),
                  //console.log("SendingMeasure: ", this.state.SendingMeasure),
            )
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadSuivi terminé !'
    } 

    async DeleteCommentaire(){    
        alert("test !")
        return 'DeleteCommentaire terminé !'
    } 

    async handleClick(){
        this.setState({ 
            SendingSuivi: true 
        }, 
        () => //console.log("SendingSuivi: ", this.state.SendingSuivi),                
        this.UpdateSuivi()
        )
    }

    renderDataSent() {
        if(this.state.SendingSuivi) {
          return (
            <div>
                <SendingData/>
            </div>
          );
        }
    }

    render() {
        return (
            !this.state.LoadingDatecode && !this.state.LoadingSuivi
            ?
            <div>
                {this.renderDataSent()}

                <BackToMenu/>

                <div className="Titre">
                    <h1>Suivi</h1>
                </div>

                <div className="Infos">
                    <div>
                        <h2> Datecode : </h2>
                        <h3> {this.state.datecode} </h3>

                    </div> 
                </div>

                <div className="control">
                    <button name="create"  className="new_measure_button" onClick={this.handleClick} >Renseigner suivi</button>
                </div> 

                <div className="control">
                    <button name="create"  className="new_comment_button" onClick={this.AddRowCommentaire} >Ajouter un commentaire</button>
                </div> 
                <div className="Table">
                    <ReactTabulator
                    ref={this.suivi}
                    columns={suivi_fsc}
                    data={this.state.fsc_suivi_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    />
                </div>
            </div>
            :
            <div>
                <LoadingData/>
            </div>
        );
    }
}
export default withRouter(SuiviFSC);