import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

class BackToMenu extends Component {

    constructor(props){
        super(props);
        this.state = {
            id_item: ''
        };
    }

    componentDidMount() {
        this.setState({
            id_item: this.props.match.params.produit,
        },  function () {});
    }
    
    handleClick = () => {
        this.props.history.push('/fsc/menu_fsc/id_item=' + this.state.id_item)                   
    }

    render() {
        return (
            <div>
                <div className="double-dropdown-button" style={{marginTop: '20px'}}>
                        <button className="new_measure_button" onClick={this.handleClick}> Retour au menu </button>
                </div>
            </div>
        );
    }
}

export default withRouter(BackToMenu);