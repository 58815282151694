import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
import { withRouter } from 'react-router-dom'
import LoadingData from '../LoadingData';
import 'react-tabulator/lib/css/tabulator.css';
import ConsultFscDataService from '../../services/data/consultfsc.service'
import FscDataService from '../../services/data/fsc.service'
import ChooseMeasure from './ChooseMeasure';
import BackToMenu from '../BackToMenu';

    /* FSC */ 
    const fsc = [                  
        {title:"Identifiant BrightLoop", field:"id_brightloop",  width:250, editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Révision FSC",           field:"rev_fsc",        width:130, editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"NCA",                    field:"rev_nca",        width:95,  editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"PDT",                    field:"rev_pdt",        width:95,  editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},  
    ];

    var fsc_data = [];

    /* DATECODE */ 
    const datecode = [                  
        {title:"Datecode", field:"datecode",  width:150, editor:false, hozAlign:"center"},
    ];

    var datecode_data = [];

    /* IDENTIFICATION FSC */ 
    const identification_fsc = [
        {title:"Identification",  field:"identifiant_brightloop",   width:370,  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Datecode",        field:"datecode",                 width:200,  editor:false,  hozAlign:"center", headerHozAlign:"center"},
        ];

    var identification_data = [];

    /* MESURE FSC */ 
    const measure_fsc = [
        {title:"Type §",          field:"type_para",         width:95,  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Numéro §",        field:"n_para",            width:110, editor:false, headerHozAlign:"center"},
        {title:"Contrôle",        field:"controle",          width:150, editor:false, headerHozAlign:"center", hozAlign:"center"},
        {title:"Type contrôle",   field:"type_controle",     width:150, editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Valeur contrôle", field:"valeur_controle",   width:150, editor:false, headerHozAlign:"center", hozAlign:"center"},
        {title:"Valeur",          field:"valeur",            width:150, editor:false, headerHozAlign:"center", hozAlign:"center"},
        {title:"Signature",       field:"signature",         width:150, editor:false, headerHozAlign:"center", hozAlign:"center"},
        ];

    var measure_data = [];

    /* SUIVI FSC */ 
    const suivi_fsc = [
        {title:"Commentaire",     field:"commentaire",  width:765,  editor:false, headerHozAlign:"center"},
        {title:"Signature",       field:"signature",    width:150,  editor:false, hozAlign:"center", headerHozAlign:"center"},
    ];

    var suivi_data = [];

class CreateMeasure extends Component {

    constructor(){
        super();
        this.state = {
            id_item: '',
            id_datecode: '',
            id_modele_mesure: '',
            id_modele_identification: '',
            id_saisie: '',
            id_brightloop: '',
            rev_fsc: '',
            rev_nca: '',
            rev_pdt: '',
            fsc_data:[],
            fsc_identification_data:[],
            fsc_measure_data:[],
            fsc_suivi_data:[],
            datecode_data: '',
            LoadingDatecode: '',
            LoadingFSC: '',
            LoadingMesure: '',
            LoadingIdentification: '',
            LoadingSuivi: ''
        };

        this.fsc = React.createRef();
        this.identification = React.createRef();
        this.mesure = React.createRef();
        this.suivi = React.createRef();
        
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            LoadingDatecode: true,
            SendingMeasure:false,
            id_item: this.props.match.params.produit,
            id_datecode: this.props.match.params.datecode
        },  function () {
                console.log("ID item : ", this.state.id_item)
                console.log("ID datecode : ", this.state.id_datecode)
                this.LoadDatecode()
            });
    }
    
    componentDidUpdate(_prevProps, prevState) {
        if (prevState.id_saisie !== this.state.id_saisie) {
            this.SelectIdModeles().then(
                exec => this.LoadFSCByModeleId().then(
                exec => this.LoadIdentification().then(
                exec => this.LoadMesure().then(
                exec => this.LoadSuivi()
                ))))    
            }
    }

    async LoadDatecode(){    
        const prom = FscDataService.LoadDatecode(this.state.id_datecode).then(res => {
            this.setState({ 
                datecode_data: res.data,
            }, 
            () => console.log("[DATECODE] Données reçues: ", this.state.datecode_data),
                this.setState({LoadingDatecode: false}
                )                   
            )        
        })

        const result = await Promise.all([ prom ]); //On attends de recevoir la promesse pour passer à la fonction suivante
        return 'LoadDatecode terminé !'
    } 

    SelectSaisie = (value) => {
        if (value == this.state.id_saisie) {
            alert('Veuillez sélectionner une saisie différente !')
        } else {
            this.setState({ 
                id_saisie: value,
                LoadingFSC: true,
                LoadingMesure: true,
                LoadingIdentification: true,
                LoadingSuivi: true
            }, 
            () => console.log("ID Saisie selected: ", this.state.id_saisie),                
            )
        }
    }
    
    async SelectIdModeles(){
        const prom1 = ConsultFscDataService.getModeleMesureId(this.state.id_saisie).then(res => {
            this.setState({ 
                id_modele_mesure: res.data[0].id_modeles_fk,
            }, 
            () => console.log("[MODELE_MESURE] ID: ", this.state.id_modele_mesure),
            )        
        })
        const prom2 = ConsultFscDataService.getModeleIdentificationId(this.state.id_saisie).then(res => {
            this.setState({ 
                id_modele_identification: res.data[0].id_modeles_fk,
            }, 
            () => console.log("[MODELE_IDENTIFICATION] ID: ", this.state.id_modele_identification)                 
            )        
        })

        const results = await Promise.all([ prom1, prom2]); //On attends de recevoir la promesse pour passer à la fonction suivante
        return 'SelectIdModeles terminé !'        
    }


    async LoadFSCByModeleId(){
        const prom = ConsultFscDataService.LoadFSCByModeleId(this.state.id_modele_mesure).then(res => { //On utilise id_modele_mesure mais id_modele_identification marche aussi car ce sont les mêmes
            this.setState({ 
                fsc_data: res.data,
                id_brightloop: res.data[0].id_brightloop,
                rev_fsc: res.data[0].rev_fsc,
                rev_nca: res.data[0].rev_nca,
                rev_pdt: res.data[0].rev_pdt 
            }, 
            () => console.log("[FSC] Données reçues: ", this.state.fsc_data),
                this.setState({LoadingFSC: false}
                )                    
            )        
        })

        const result = await Promise.all([ prom ]); //On attends de recevoir la promesse pour passer à la fonction suivante
        return 'LoadFSC terminé !'
    }

    async LoadIdentification(){    
        const prom = ConsultFscDataService.LoadIdentification(this.state.id_saisie).then(res => {
            this.setState({ 
                fsc_identification_data: res.data 
            }, 
            () => console.log("[IDENTIFICATION] Données reçues: ", this.state.fsc_identification_data),
                this.setState({ LoadingIdentification: false}, 
                //() => console.log("ModeleIdentificationLoaded: ", this.state.ModeleIdentificationLoaded),
                )                
            )
        })

        const result = await Promise.all([ prom ]); //On attends de recevoir la promesse pour passer à la fonction suivante
        return 'LoadIdentification terminé !'
    }

    async LoadMesure(){    
        const prom = ConsultFscDataService.LoadMesure(this.state.id_saisie).then(res => {
            this.setState({ 
                fsc_measure_data: res.data 
            }, 
            () => console.log("[MESURE] Données reçues: ", this.state.fsc_measure_data),
                this.setState({ LoadingMesure: false}, 
                //() => console.log("ModeleMesureLoaded: ", this.state.ModeleMesureLoaded),
                )                
            )
        })

        const result = await Promise.all([ prom ]); //On attends de recevoir la promesse pour passer à la fonction suivante
        return 'LoadMesure terminé !'
    }

    async LoadSuivi(){    
        const prom = ConsultFscDataService.LoadSuiviByDatecodeId(this.state.id_datecode).then(res => {
            this.setState({ 
                fsc_suivi_data: res.data 
            }, 
            () => console.log("[SUIVI] Données reçues: ", this.state.fsc_suivi_data),
                this.setState({ LoadingSuivi: false}, 
                //() => console.log("ModeleMesureLoaded: ", this.state.ModeleMesureLoaded),
                )                
            )
        })

        const result = await Promise.all([ prom ]); //On attends de recevoir la promesse pour passer à la fonction suivante
        return 'LoadSuivi terminé !'
    }

    async handleClick(){
        if (this.state.id_saisie) {
            window.open("/fsc/consult_fsc/id_item=" + this.state.id_item + "/id_datecode=" + this. state.id_datecode + "/generate_pdf/id_mesure=" + this.state.id_saisie, '_blank');
        } else {
            alert('Veuillez sélectionner une mesure !')
        }
    }

    render() {
        return (
            !this.state.LoadingDatecode

            ?

            !this.state.LoadingFSC && !this.state.LoadingIdentification && !this.state.LoadingMesure && !this.state.LoadingSuivi
            
            ?

            <div>

                <BackToMenu/>

                <div className="Titre">
                    <h1>Consulter</h1>
                </div>

                <ChooseMeasure
                SelectSaisie = {this.SelectSaisie}
                />

                <div className="Infos">
                    <div>
                        <h2> {this.state.id_brightloop}</h2>
                        <h3> Fiche de suivi et Consignation : {this.state.id_brightloop}_FSC_{this.state.rev_fsc} </h3>
                        <h3> Notice de Câblage & Assemblage : {this.state.id_brightloop}_NCA_{this.state.rev_nca} </h3>
                        <h3> Notice de Câblage & Assemblage : {this.state.id_brightloop}_PDT_{this.state.rev_pdt} </h3>
                    </div> 
                </div>

                <div className="control">
                    <button name="pdf"  className="new_measure_button" onClick={this.handleClick}> Generate PDF</button>
                </div>  

                <div className="Table">
                    <ReactTabulator
                    ref={this.fsc}                    
                    columns={fsc}
                    data={this.state.fsc_data}
                    tooltips={true}
                    layout={"fitColumns"}
                    history={true}               
                    resizableRows={false}               
                    headerSort={false}
                    headerHozAlign={"center"}
                    />

                    <div className="Table_Datecode">
                        <ReactTabulator
                        ref={this.datecode}                    
                        columns={datecode}
                        data={this.state.datecode_data}
                        tooltips={true}
                        layout={"fitColumns"}
                        history={true}               
                        resizableRows={false}               
                        headerSort={false}
                        headerHozAlign={"center"}
                        />
                    </div>
                </div>

                <div className="Table">
                    <ReactTabulator
                    ref={this.identification}
                    columns={identification_fsc}
                    data={this.state.fsc_identification_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    headerHozAlign={"center"}
                    />
                </div>

                <div className="Table">
                    <ReactTabulator
                    ref={this.mesure}
                    columns={measure_fsc}
                    data={this.state.fsc_measure_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    headerHozAlign={"center"}
                    />
                </div>

                <div className="Table">
                    <ReactTabulator
                    ref={this.suivi}
                    columns={suivi_fsc}
                    data={this.state.fsc_suivi_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    />
                </div>
                
            </div>
            :
            <div>
                <LoadingData/>
            </div>
            :
            <div>
                <LoadingData/>
            </div>
        );
    }
}
export default withRouter(CreateMeasure);