import http from "../../http-common";

class CreateDatecodeDataService {

    createDatecode(id_item, datecode) {
        return http.post('/create_datecode_fsc/' + id_item, datecode) 
        .then(function (response) {
            console.log(response.data);
            alert("Datecode créé !")
        })
        .catch(function (error) {
            console.log(error.response.data);
            alert("Erreur dans le datecode ! Vérfiez que tous les champs sont corrects/remplis !")
        });
    }
}

export default new CreateDatecodeDataService();