import React, {useEffect, useState} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import DatecodeDataService from '../../services/data/selectdatecode.service';
import LoadingData from '../LoadingData';

const SelectDatecode = () => {
    const history = useHistory();

    const [ListId, setListId] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [LoadingDatecode, setLoadingDatecode] = useState(true);
    const { produit } = useParams(); //produit = id_item

    function getChoixId(e) {
        setSelectedId(e.target.value)
    }

    useEffect(()=>{
        DatecodeDataService.getDatecodesByItem(produit)
        .then(response => {
            console.log("[DATECODES] Données reçues : ", response.data)
            setListId(response.data)
            setLoadingDatecode(false)
            if (response.data.length == 0) {
                alert('Aucun datecode disponible pour ce produit !')
                history.goBack()
            }
        })
    }, [])

    function handleSubmit(e) {
        //alert('Datecode ID selected: ' + selectedId)
        e.preventDefault()
        history.push('/fsc/suivi_fsc/id_item=' + produit + '/id_datecode=' + selectedId)
    }

    return (
        !LoadingDatecode
        ?
        <div>
            <div className="double-dropdown" style={{marginTop:'25px'}}>                
                <select className="choixtype" id="choix_fsc" onChange={getChoixId}>
                    <option value="">-- Choix du datecode --</option>
                    {Object
                    .keys(ListId)
                    .map((item, i) => 
                    <option key={i} value={ListId[item].id}
                    >{ListId[item].datecode}</option>)
                    }       
                </select> 
            </div>

            <div className="double-dropdown-button">
                <form method="get" name="envoi" onSubmit={handleSubmit}>
                    <button type="submit" className="button"> Sélectionner </button>
                </form>
            </div>
        </div>
        :
        <div>
            <LoadingData/>
        </div>
   );
};

export default withRouter(SelectDatecode);