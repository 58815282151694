import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import ProduitDataService from '../../services/data/produit.service';
import LoadingData from '../LoadingData';

class MenuFSC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            produit: '',
            productInfos: '',
            LoadingInfos : true
        };
        this.goToCreateDatecode = this.goToCreateDatecode.bind(this);
        this.goToCreateFSC = this.goToCreateFSC.bind(this);
        this.goToSelectDatecodeMeasure = this.goToSelectDatecodeMeasure.bind(this);
        this.goToSelectDatecodeConsult = this.goToSelectDatecodeConsult.bind(this);
        this.goToSelectDatecodeSuivi = this.goToSelectDatecodeSuivi.bind(this);
        this.goToReviseFSC = this.goToReviseFSC.bind(this);
    }

    componentDidMount() {
        this.setState({
            produit: this.props.match.params.produit
        }, function () {
                console.log(this.state.produit);
            });

        ProduitDataService.getProductInfos(this.props.match.params.produit)
        .then(response => {
            if (response.data[0].type_item == 1) {
                response.data[0].type_item = "BLV"
            } else if (response.data[0].type_item == 2) {
                response.data[0].type_item = "ENS"
            } else if (response.data[0].type_item == 3) {
                response.data[0].type_item = "CAR"
            } else if (response.data[0].type_item == 4) {
                response.data[0].type_item = "SOF"
            } else if (response.data[0].type_item == 5) {
                response.data[0].type_item = "PLD"
            } else if (response.data[0].type_item == 0) {
                response.data[0].type_item = "PRO"
            }

            this.setState({
                productInfos: response.data,
                LoadingInfos: false
            }, function () {
                console.log(response.data);
            });
        })
    }
    
    goToCreateDatecode(e) {
        e.preventDefault()
        this.props.history.push('/fsc/create_datecode/id_item=' + this.state.produit)
    }
    goToCreateFSC(e) {
        e.preventDefault()
        this.props.history.push('/fsc/create_fsc/id_item=' + this.state.produit)
    }
    goToSelectDatecodeMeasure(e) {
        e.preventDefault()
        this.props.history.push('/fsc/select_datecode_measure/id_item=' + this.state.produit)
    }
    goToSelectDatecodeConsult(e) {
        e.preventDefault()
        this.props.history.push('/fsc/select_datecode_consult/id_item=' + this.state.produit)
    }
    goToSelectDatecodeSuivi(e) {
        e.preventDefault()
        this.props.history.push('/fsc/select_datecode_suivi/id_item=' + this.state.produit)
    }
    goToReviseFSC(e) {
        e.preventDefault()
        this.props.history.push('/fsc/select_fsc_revise/id_item=' + this.state.produit)
    }


    render() {
        return (
        !this.state.LoadingInfos
        ?
        <div className="content">
            <div className="infos">
                
                <h3>ID PRODUIT : {this.state.produit} </h3>
             
                {Object
                    .keys(this.state.productInfos)
                    .map((item, i) => 
                    <p key={i} value={this.state.productInfos[item].name_item}
                    >Produit sélectionné : <strong> {this.state.productInfos[item].type_item}_
                                                    {this.state.productInfos[item].num_item}_
                                                    {this.state.productInfos[item].rev_item}_
                                                    {this.state.productInfos[item].name_item}
                                            </strong>
                    </p>)
                } 
            
                {Object
                    .keys(this.state.productInfos)
                    .map((item, i) => 
                    <p key={i} value={this.state.productInfos[item].name_item}
                    >Description : {this.state.productInfos[item].com_item}</p>)
                } 
            
                {Object
                    .keys(this.state.productInfos)
                    .map((item, i) => 
                    <p key={i} value={this.state.productInfos[item].name_item}
                    >Date de création : {this.state.productInfos[item].date_creation_item}</p>)
                }
            </div>

            <div className="buttons">
                <div className="colonne1-buttons">
                    <form className="form" method="get" name="envoi" onSubmit={this.goToCreateDatecode}>
                        <button type="submit" className="button"> Renseigner un Datecode </button>
                    </form>
                    <form className="form" method="get" name="envoi" onSubmit={this.goToSelectDatecodeConsult}>
                        <button type="submit" className="button"> Consulter une FSC</button>
                    </form>
                    <form className="form" method="get" name="envoi" onSubmit={this.goToSelectDatecodeSuivi}>
                        <button type="submit" className="button"> Suivi</button>
                    </form>    
                </div>

                <div className="colonne2-buttons">
                    <form className="form" method="get" name="envoi" onSubmit={this.goToCreateFSC}>
                        <button type="submit" className="button"> Créer une FSC </button>
                    </form> 
                    <form className="form" method="get" name="envoi" onSubmit={this.goToReviseFSC}>
                        <button type="submit" className="button"> Réviser une FSC </button>
                    </form>
                    {/* <form className="form" method="get" name="envoi">
                        <button type="submit" className="button"> Supprimer un modèle </button>
                    </form>                     */}

                </div>  

                <div className="colonne3-buttons">
                    <form className="form" method="get" name="envoi" onSubmit={this.goToSelectDatecodeMeasure}>
                        <button type="submit" className="button"> Renseigner une saisie </button>
                    </form>  
                    {/* <form className="form" method="get" name="envoi">
                        <button type="submit" className="button"> Supprimer une mesure </button>
                    </form> */}

                </div>
                <div className="colonne4-buttons">
                </div>
            </div>   
        </div>
        :
        <div>
            <LoadingData/>
        </div>
        )
    }
}

export default withRouter(MenuFSC);
