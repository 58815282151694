import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import SendingData from '../SendingData';
import CreateDatecodeDataService from '../../services/data/createdatecode.service'
import 'react-tabulator/lib/css/tabulator.css';
import BackToMenu from '../BackToMenu';

class CreateDatecode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            produit: '',
            id_item: '',
            datecode: '',
            SendingDatecode:''       
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);    
    }

    async CreateDatecode() {
        //Création Datecode
        var datecode_json = [
            {
                datecode: this.state.datecode
            }
        ]
        CreateDatecodeDataService.createDatecode(this.state.produit, datecode_json).then(res => {
            this.setState({ 
                SendingDatecode: false 
            }, 
            () => console.log("Sending datecode: ", this.state.SendingDatecode),
            this.props.history.goBack()
            )
        })
    }

    componentDidMount(){

        this.setState({
            produit: this.props.match.params.produit
        },  function () {
                console.log(this.state.produit);
            });
    }

    handleChange(event) {
        this.setState({datecode: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ 
            SendingDatecode: true, 
        }, 
        () => console.log("Sending datecode: ", this.state.SendingDatecode),
            this.CreateDatecode()
        )
    }

    renderDataSent() {
        if(this.state.SendingDatecode) {
          return (
            <div>
                <SendingData/>
            </div>
          );
        }
    }

    render() {
        return (
        <div>       

            {this.renderDataSent()}

            <BackToMenu/>

            <div className="Titre">
                <h1>Renseigner un Datecode</h1>
            </div>

            <div className="login-form">
                <form onSubmit={this.handleSubmit}>
                    <label>
                        <input
                            autoComplete="off"
                            type="text"
                            value={this.state.datecode} 
                            onChange={this.handleChange}
                            name="datecode"
                            placeholder="AASSXXX"
                            maxLength="7"
                            minLength="7"
                            required
                            />                
                    </label>

                    <div className="login-button">
                        <input type="submit" className="button" value="Envoyer" />
                    </div>
                </form>
            </div>
        </div>
        );
    }
}

export default withRouter(CreateDatecode);