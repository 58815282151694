import React from 'react';

const notFound = () => {
    return (
        <div className="error404">
            <h1>Error 404</h1>
            <p>Page not found !</p>
        </div>
    );
};

export default notFound;