import http from "../../http-common";

class CreateMeasureDataService {

    createSaisie(id_modele, id_datecode) {
        //SAISIES
        return http.post('/create_saisie/' + id_modele + '/' + id_datecode) 
        .then(function (response) {
                console.log(response.data);
        })
        .catch(function (error) {
            alert("Erreur ! Création saisie impossible !")
            console.log("Erreur création saisie: ", error.response.data);
        });
    }

    createMeasureFSC(data_mesure) {
        return http.post('/create_mesure_fsc', data_mesure) 
        .then(function (response) {
            console.log(response.data)
        })
        .catch(function (error) {
            console.log(error.response.data)
            alert("Erreur dans la mesure ! Vérfiez que tous les champs sont corrects/remplis !")
        });
    }

    createIdentificationFSC(data_identification) {
        return http.post('/create_identification_fsc', data_identification) 
        .then(function (response) {
            console.log(response.data)
        })
        .catch(function (error) {
            console.log(error.response.data)
            alert("Erreur dans l'identification ! Vérfiez que tous les champs sont corrects/remplis !")
        });
    }

    createSuiviFSC(data_modele, id_fsc) {
        return http.post('/create_suivi_fsc' + id_fsc, data_modele) 
        .then(function (response) {
            console.log(response.data);
            alert("Mesure créée !")
        })
        .catch(function (error) {
            console.log(error.response.data);
            alert("Erreur dans le suivi ! Vérfiez que tous les champs sont corrects/remplis !")
        });
    }
}

export default new CreateMeasureDataService();