import React from 'react';
import LoginNavbar from '../components/LoginNavbar';

const root = () => {

    return(
        <div className="root">
            <LoginNavbar/>

            <div className="root-info">
                <h1>Brightloop Product Information Management</h1>
                <p>Cliquer sur "Login" pour vous connecter !</p>
            </div>
        </div>
    )
}

export default root;