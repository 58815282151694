import React from 'react';
import Navbar from '../components/Navbar';

const contact = () => {
    return (
        <div>
            <Navbar/>
            <div className="contact">
                <p className="contact-message"><b>Administrateur :</b> qualite@brightloop.fr <br></br>
                   Merci d'envoyer un mail à l'adresse ci-dessus pour tout retour concernant BrightLoop PIM (bug, identifiants oubliés etc..)
                </p>
            </div>
        </div>
    );
};

export default contact;