import React from 'react';
import SelectDatecodeConsult from '../../components/FSC/SelectDatecodeConsult';
import Navbar from '../../components/Navbar';

const select_datecode_consult = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <SelectDatecodeConsult/>
        </div>
    </div>
    );
};

export default select_datecode_consult;