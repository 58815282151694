import React from 'react';
import SelectDatecodeSuivi from '../../components/FSC/SelectDatecodeSuivi';
import Navbar from '../../components/Navbar';

const select_datecode_suivi = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <SelectDatecodeSuivi/>
        </div>
    </div>
    );
};

export default select_datecode_suivi;