import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
import { withRouter } from 'react-router-dom'
import LoadingData from '../LoadingData';
import SendingData from '../SendingData';
import 'react-tabulator/lib/css/tabulator.css';
import FscDataService from '../../services/data/fsc.service'
import CreateMeasureDataService from '../../services/data/createmeasure.service'
import ChooseFSC from './ChooseFSC';
import BackToMenu from '../BackToMenu';

    /* FSC */ 
    const fsc = [                  
        {title:"Identifiant BrightLoop", field:"id_brightloop",  width:250, editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Révision FSC",           field:"rev_fsc",        width:130, editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"NCA",                    field:"rev_nca",        width:95,  editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"PDT",                    field:"rev_pdt",        width:95,  editor:"input",  editor:false, hozAlign:"center", headerHozAlign:"center"},  
    ];

    var fsc_data = [];

    /* DATECODE */ 
    const datecode = [                  
        {title:"Datecode", field:"datecode",  width:150, editor:false, hozAlign:"center"},
    ];

    var datecode_data = [
        {
            datecode: "AASSxxx",
        },
    ];

    /* IDENTIFICATION FSC */ 
    const identification_fsc = [
        {title:"Identification",  field:"identifiant_brightloop",   width:370,  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Datecode",        field:"datecode",                 width:200,  editor:true,  hozAlign:"center", headerHozAlign:"center"},
        ];

    var identification_data = [];

    /* MESURE FSC */ 
    const measure_fsc = [
        {title:"Type §",          field:"type_para",         width:95,  editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Numéro §",        field:"n_para",            width:110, editor:false, headerHozAlign:"center"},
        {title:"Contrôle",        field:"controle",          width:150, editor:false, headerHozAlign:"center", hozAlign:"center"},
        {title:"Type contrôle",   field:"type_controle",     width:150, editor:false, hozAlign:"center", headerHozAlign:"center"},
        {title:"Valeur contrôle", field:"valeur_controle",   width:150, editor:false, headerHozAlign:"center", hozAlign:"center"},
        {title:"Valeur",          field:"valeur",            width:150, editor:"input",  editor:true, headerHozAlign:"center", hozAlign:"center"},
        {title:"Signature",       field:"signature",         width:150, editor:"input",  editor:true, headerHozAlign:"center", hozAlign:"center"},
        ];

    var measure_data = [];

    /* SUIVI FSC */ 
    const suivi_fsc = [
        {
            formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell){
                if(window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) cell.getRow().delete()
            }
        },
        {title:"Commentaire",     field:"commentaire",  width:765,  editor:"input",  editor:true, headerHozAlign:"center"},
        {title:"Signature",       field:"signature",    width:150,  editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},
    ];

    var th = [];

class CreateMeasure extends Component {

    constructor(){
        super();
        this.state = {
            produit: '',
            id_datecode: '',
            id_modele: '',
            id_fsc: '',
            id_brightloop: '',
            rev_fsc: '',
            rev_nca: '',
            rev_pdt: '',
            fsc_data:[],
            fsc_identification_data:[],
            fsc_measure_data:[],
            fsc_suivi_data:[],
            id_modele_identification: '',
            datecode_data: '',
            LoadingDatecode: '',
            LoadingFSC: '',
            LoadingModeleMesure: '',
            LoadingModeleIdentification: '',
            SendingMeasure: '',
        };

        this.fsc = React.createRef();
        this.identification = React.createRef();
        this.mesure = React.createRef();
        this.suivi = React.createRef();

        this.CreateMeasure= this.CreateMeasure.bind(this);
        this.CreateSuivi= this.CreateSuivi.bind(this);
        this.handleClick = this.handleClick.bind(this);
        
        this.AddRowCommentaire= this.AddRowCommentaire.bind(this);
    }

    componentDidMount() {
        this.setState({
            LoadingDatecode: true,
            SendingMeasure:false,
            produit: this.props.match.params.produit,
            id_datecode: this.props.match.params.datecode
        },  function () {
                console.log("ID item : ", this.state.produit)
                console.log("ID datecode : ", this.state.id_datecode)
                this.LoadDatecode()
            });
    }
    
    componentDidUpdate(_prevProps, prevState) {
        if (prevState.id_fsc !== this.state.id_fsc) {
            this.SelectIDModele().then(
                exec => this.LoadFSC().then(
                exec => this.LoadModeleMesure().then(
                exec => this.LoadModeleIdentification()
                )))
        }
    }

    AddRowCommentaire() {
        this.suivi.current.table.addRow(false);
    }

    SelectFSC = (value) => {
        if (value == this.state.id_fsc) {
            alert('Veuillez sélectionner une mesure différente !')
        } else {
            this.setState({ 
                id_fsc: value,
                LoadingFSC: true,
                LoadingModeleIdentification: true,
                LoadingModeleMesure: true
            }, 
            () => console.log("ID FSC selected: ", this.state.id_fsc)
            )
        }
    }

    async SelectIDModele(){    
        const prom = FscDataService.SelectIDModele(this.state.id_fsc).then(res => {
            this.setState({ 
                id_modele: res.data[0].id_modeles_fk 
            }, 
            () => console.log("[MODELE] ID: ", this.state.id_modele)               
            )
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'SelectIDModeleMesure terminé !'
    }
    
    async LoadDatecode(){    
        const prom = FscDataService.LoadDatecode(this.state.id_datecode).then(res => {
            this.setState({ 
                datecode_data: res.data,
            }, 
            () => console.log("[DATECODE] Données reçues: ", this.state.datecode_data),
                this.setState({LoadingDatecode: false})                   
            )        
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadDatecode terminé !'
    } 

    async LoadFSC(){
        const prom = FscDataService.LoadFSC(this.state.id_fsc).then(res => {
            this.setState({ 
                fsc_data: res.data,
                id_brightloop: res.data[0].id_brightloop,
                rev_fsc: res.data[0].rev_fsc,
                rev_nca: res.data[0].rev_nca,
                rev_pdt: res.data[0].rev_pdt 
            }, 
            () => console.log("[FSC] Données reçues: ", this.state.fsc_data),
                this.setState({LoadingFSC: false}
                )                    
            )        
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadFSC terminé !'
    }


    async LoadModeleMesure(){    
        const prom = FscDataService.LoadModeleMesure(this.state.id_modele).then(res => {
            this.setState({ 
                fsc_measure_data: res.data 
            }, 
            () => console.log("[MODELE_MESURE] Données reçues: ", this.state.fsc_measure_data),
                this.setState({ LoadingModeleMesure: false}, 
                //() => console.log("ModeleMesureLoaded: ", this.state.ModeleMesureLoaded),
                )                
            )
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadModeleMesure terminé !'
    }

    async LoadModeleIdentification(){    
        const prom = FscDataService.LoadModeleIdentification(this.state.id_modele).then(res => {
            this.setState({ 
                fsc_identification_data: res.data 
            }, 
            () => console.log("[MODELE_IDENTIFICATION] Données reçues: ", this.state.fsc_identification_data),
                this.setState({ LoadingModeleIdentification: false}, 
                //() => console.log("ModeleIdentificationLoaded: ", this.state.ModeleIdentificationLoaded),
                )                
            )
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadModeleIdentification terminé !'
    }

    async CreateSaisie() {

        console.log("[SAISIES] Création d'une saisie...")
        
        console.log("[SAISIES] Données envoyées au serveur : ", this.state.id_modele, ", ", this.state.id_datecode)
        const prom = CreateMeasureDataService.createSaisie(this.state.id_modele, this.state.id_datecode)
        
        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateSaisie terminé !'
        
    }

    async CreateMeasure(){
        //Création Measure
        var data_mesure = this.mesure.current.table.getData();
        console.log("[MESURE] Données envoyées au serveur : ", data_mesure)
        const prom = CreateMeasureDataService.createMeasureFSC(data_mesure)

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'measure sent'
    }

    async CreateIdentification(){
        //Création Measure
        var data_identification = this.identification.current.table.getData();
        console.log("[IDENTIFICATION] Données envoyées au serveur : ", data_identification)
        const prom = CreateMeasureDataService.createIdentificationFSC(data_identification)

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'identification sent'
    }

    async CreateSuivi(){
        //Création Suivi
        var data_suivi = this.suivi.current.table.getData();
        console.log("[SUIVI] Données envoyées au serveur : ", data_suivi, ", ",this.state.id_datecode)
        const prom = CreateMeasureDataService.createSuiviFSC(data_suivi, this.state.id_datecode).then(res => {
            this.setState({ 
                SendingMeasure: false 
            }, 
            //() => console.log("SendingMeasure: ", this.state.SendingMeasure),
            this.props.history.push('/fsc/menu_fsc/id_item=' + this.state.produit)                   
            )
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'suivi sent'
    }

    async handleClick(){
        this.setState({ 
            SendingMeasure: true 
        }, 
        () => //console.log("SendingMeasure: ", this.state.SendingMeasure),
        this.CreateSaisie().then(                
            exec => this.CreateMeasure().then(
            exec => this.CreateIdentification().then(
            exec => this.CreateSuivi()))
        ),
        )
    }

    renderDataSent() {
        if(this.state.SendingMeasure) {
          return (
            <div>
                <SendingData/>
            </div>
          );
        }
    }

    render() {
        return (
            !this.state.LoadingFSC && !this.state.LoadingModeleIdentification && !this.state.LoadingModeleMesure && !this.state.LoadingDatecode
            ?
            <div>
                {this.renderDataSent()}

                <BackToMenu/>

                <div className="Titre">
                    <h1>Renseigner une saisie</h1>
                </div>

                <ChooseFSC
                SelectFSC = {this.SelectFSC}
                />

                <div className="Infos">
                    <div>
                        <h2> {this.state.id_brightloop}</h2>
                        <h3> Fiche de suivi et Consignation : {this.state.id_brightloop}_FSC_{this.state.rev_fsc} </h3>
                        <h3> Notice de Câblage & Assemblage : {this.state.id_brightloop}_NCA_{this.state.rev_nca} </h3>
                        <h3> Notice de Câblage & Assemblage : {this.state.id_brightloop}_PDT_{this.state.rev_pdt} </h3>
                    </div> 
                </div>

                <div className="control">
                    <button name="create"  className="new_measure_button" onClick={this.handleClick} >Renseigner FSC</button>
                </div>  
        
                <div className="Table">
                    <ReactTabulator
                    ref={this.fsc}                    
                    columns={fsc}
                    data={this.state.fsc_data}
                    tooltips={true}
                    layout={"fitColumns"}
                    history={true}               
                    resizableRows={false}               
                    headerSort={false}
                    headerHozAlign={"center"}
                    />

                    <div className="Table_Datecode">
                        <ReactTabulator
                        ref={this.datecode}                    
                        columns={datecode}
                        data={this.state.datecode_data}
                        tooltips={true}
                        layout={"fitColumns"}
                        history={true}               
                        resizableRows={false}               
                        headerSort={false}
                        headerHozAlign={"center"}
                        />
                    </div>
                </div>

                <div className="Table">
                    <ReactTabulator
                    ref={this.identification}
                    columns={identification_fsc}
                    data={this.state.fsc_identification_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    headerHozAlign={"center"}
                    />
                </div>

                <div className="Table">
                    <ReactTabulator
                    ref={this.mesure}
                    columns={measure_fsc}
                    data={this.state.fsc_measure_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    headerHozAlign={"center"}
                    />
                </div>
        
                <div className="control">
                    <button name="create"  className="new_comment_button" onClick={this.AddRowCommentaire} >Ajouter un commentaire</button>
                </div> 

                <div className="Table">
                    <ReactTabulator
                    ref={this.suivi}
                    columns={suivi_fsc}
                    data={this.state.fsc_suivi_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    />
                </div>
            </div>
            :
            <div>
                <LoadingData/>
            </div>
        );
    }
}
export default withRouter(CreateMeasure);