import React, {useEffect, useState} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import ConsultFscDataService from '../../services/data/consultfsc.service'

const ChooseMeasure = (props) => {
    const history = useHistory();

    const [ListId, setListId] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const { produit } = useParams();
    const { datecode } = useParams();

    function getChoixId(e) {
        setSelectedId(e.target.value)
    }

    useEffect(()=>{
        ConsultFscDataService.getSaisiesbyDatecode(datecode)
        .then(response => {
            //console.log("[SAISIES] Données reçues : ", response.data)
            for (let i = 0; i < response.data.length; i++) {
                response.data[i].created_at = response.data[i].created_at //formatter date
            }
            setListId(response.data)
            if (response.data.length == 0) {
                alert('Aucunes saisies disponible pour ce produit !')
                history.push('/fsc/menu_fsc/id_item=' + produit)                   
            }
        })
    }, [])

    function handleSubmit(e) {
        e.preventDefault()
        //alert('Measure ID selected: ' + selectedId)
        if (selectedId <= null) {
            alert("Veuillez sélectionner une mesure correcte !")
        } else {
            props.SelectSaisie(selectedId)
        }
    }

    return (
        <div  className="double-dropdown">
            <div style={{float: 'left'}}>                
                <select className="choixtype" id="choix_fsc" onChange={getChoixId} style={{marginTop:'33px'}}>
                    <option value="">-- Choix de la saisie --</option>
                    {Object
                    .keys(ListId)
                    .map((item, i) => 
                    <option key={i} value={ListId[item].id}
                    >Saisie du {ListId[item].created_at}</option>)
                    }       
                </select> 
            </div>

            <div style={{float: 'left'}}>                
                <form method="get" name="envoi" onSubmit={handleSubmit}>
                    <button type="submit" className="button"> Consulter la mesure </button>
                </form>
            </div>
        </div>
   );
};

export default withRouter(ChooseMeasure);