import React from 'react';
import { NavLink } from "react-router-dom";

class Navbar extends React.Component {
    render() {
        return(
            <nav className="NavbarItems">
                <h1 className="Title"> BrightLoop PIM </h1>
                <NavLink exact to ="/accueil" activeClassName="nav-active"> Accueil </NavLink>  
                <NavLink exact to ="/fsc"     activeClassName="nav-active"> FSC     </NavLink>
                <NavLink exact to ="/about"   activeClassName="nav-active"> About   </NavLink>
                <NavLink exact to ="/contact" activeClassName="nav-active"> Contact </NavLink>
                <p className="connected">Connecté en tant que : user </p>
                <button className="disconnect"> Se déconnecter</button>
            </nav>
        );
    };
};

export default Navbar;