import { useState } from "react";
import Axios from "axios";
import Navbar from "../components/Navbar";

const Accueil = () => {

    const [userList, setUserList] = useState([]);

    const getUsers = () => {
        Axios.get("http://localhost:3001/users").then((response) => {
            setUserList(response.data);
        });
    };

    return(
        <div className="home">
            <Navbar/>

            <div className="about">
                <p className="about-info">Bienvenue !</p>
            </div>

        </div>
    )
}

export default Accueil;