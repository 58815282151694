import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import ProduitDataService from '../services/data/produit.service'
import TypeDataService from '../services/data/type.service'
import LoadingData from './LoadingData';
import NumberFormat from 'react-number-format';

const DoubleDropdown = () => {
    const history = useHistory();

    const [types, setTypes] = useState([]);
    const [produits, setProduits] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedProduit, setSelectedProduit] = useState('');
    const [LoadingType, setLoadingType] = useState(true);

    function getChoixType(e) {
        setSelectedType(e.target.value)
    }

    function getChoixProduit(e) {
        setSelectedProduit(e.target.value)
    }

    useEffect(()=>{
        TypeDataService.getAllTypes()
        .then(response => {
            console.log(response.data)
            setTypes(response.data)
            setLoadingType(false)
        })
    }, [])
   
    useEffect(()=>{
        ProduitDataService.getByType(selectedType)
        .then(response => {
            console.log(response.data)
            setProduits(response.data)
        })
    }, [selectedType])

    function handleSubmit(e) {
        //alert('A product was submitted: ' + selectedProduit)
        e.preventDefault()
        history.push('/fsc/menu_fsc/id_item=' + selectedProduit)
    }

    return (
        !LoadingType
        ?
        <div>
            <div className="double-dropdown" style={{marginTop:'25px'}}>                
                <select className="choixtype" id="choix_type" onChange={getChoixType}>
                    <option value="">-- Choix du type --</option>
                    {Object
                    .keys(types)
                    .map((item, i) => 
                    <option key={i} value={types[item].id_cat}
                    >{types[item].name_cat} ({types[item].num_type_item})</option>)
                    }       
                </select> 
                
                <select className="choixproduit" id="choix_produit" onChange={getChoixProduit}>
                    <option value="">-- Choix du produit --</option>
                    {Object
                    .keys(produits, types)
                    .map((item, i) => 
                    <option key={i} value={produits[item].id_item}
                    >{produits[item].type_item}_{produits[item].num_item}_{produits[item].rev_item}_{produits[item].name_item}</option>)
                    //A formatter :
                    //BLV_001_00_LARGE2
                    //Type_item_Num_item_Rev_item_Name_item
                    }       
                </select>
            </div>
 
            <div className="double-dropdown-info">
                <p className="type-info"><b>Type sélectioné :   </b> {selectedType}</p>
                <p className="type-info"><b>Produit sélectioné :</b> {selectedProduit}</p>
            </div>

            <div className="double-dropdown-button">
                <form class="form" method="get" name="envoi" onSubmit={handleSubmit}>
                    <button type="submit" className="button"> Sélectionner </button>
                </form>
            </div>
        </div>
        :
        <div>
            <LoadingData/>
        </div>
   );
};

export default DoubleDropdown;