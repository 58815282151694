import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import root from './views/root';
import login from './views/login';
import accueil from './views/accueil';
import fsc from './views/FSC/fsc';
import menu_fsc from './views/FSC/menu_fsc';
import create_datecode from './views/FSC/create_datecode';
import create_fsc from './views/FSC/create_fsc';
import select_datecode_measure from './views/FSC/select_datecode_measure';
import select_datecode_consult from './views/FSC/select_datecode_consult';
import select_fsc_revise from './views/FSC/select_fsc_revise';
import create_measure from './views/FSC/create_measure';
import about from './views/about';
import contact from './views/contact';
import notFound from './views/notFound';
import modify_measure from './views/FSC/modify_measure';
import consult_fsc from './views/FSC/consult_fsc';
import suivi_fsc from './views/FSC/suivi_fsc';
import select_datecode_suivi from './views/FSC/select_datecode_suivi';
import revise_fsc from './views/FSC/revise_fsc';
import generate_pdf from './views/FSC/generate_pdf';
  
function App(){
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path ="/"                          exact component={root} />
        <Route exact path ="/login"                     exact component={login} />
        <Route exact path ="/accueil"                   exact component={accueil} />
        <Route exact path ="/about"                     exact component={about} />
        <Route exact path ="/contact"                   exact component={contact} />
        <Route exact path ="/fsc"                       exact component={fsc} />
        <Route path ="/fsc/menu_fsc/id_item=:produit"                               exact component={menu_fsc} />
        <Route path ="/fsc/create_datecode/id_item=:produit"                        exact component={create_datecode} />
        <Route path ="/fsc/create_fsc/id_item=:produit"                             exact component={create_fsc} />
        <Route path ="/fsc/revise_fsc/id_item=:produit/id_fsc=:fsc"                 exact component={revise_fsc} />
        <Route path ="/fsc/select_datecode_measure/id_item=:produit"                exact component={select_datecode_measure} />
        <Route path ="/fsc/select_datecode_consult/id_item=:produit"                exact component={select_datecode_consult} />
        <Route path ="/fsc/select_datecode_suivi/id_item=:produit"                  exact component={select_datecode_suivi} />
        <Route path ="/fsc/select_fsc_revise/id_item=:produit"                      exact component={select_fsc_revise} />
        <Route path ="/fsc/create_measure/id_item=:produit/id_datecode=:datecode"   exact component={create_measure} />
        <Route path ="/fsc/modify_measure/id_item=:produit"                         exact component={modify_measure} />
        <Route path ="/fsc/suivi_fsc/id_item=:produit/id_datecode=:datecode"        exact component={suivi_fsc} />
        <Route path ="/fsc/consult_fsc/id_item=:produit/id_datecode=:datecode"      exact component={consult_fsc} />

        <Route path ="/fsc/consult_fsc/id_item=:produit/id_datecode=:datecode/generate_pdf/id_mesure=:mesure"     exact component={generate_pdf} />

        <Route  exact component={notFound}/>
      </Switch>
    </BrowserRouter>
  );
};

export default App;