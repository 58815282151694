import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ReactToPdf from 'react-to-pdf';


class GeneratePDF extends Component {

    constructor(){
        super();
        this.state = {
            id_item: '',
            id_datecode: '',
            id_mesure: ''
        };
    }

    componentDidMount() {
        this.setState({
            id_item: this.props.match.params.produit,
            id_datecode: this.props.match.params.datecode,
            id_mesure: this.props.match.params.mesure

        },  function () {
                console.log("ID item : ", this.state.id_item)
                console.log("ID datecode : ", this.state.id_datecode)
                console.log("ID mesure : ", this.state.id_mesure)
            });
    }

    renderPDF() {
        return (
            <div style={{visibility: 'visible', margin: '50px'}}>
               
                {/* PAGE 1 */}
                <div className="page">
                    
                <div className="row">
                    <img src="http://localhost/img/logo1.png" width={266} height={100} style={{float: 'left'}}/>
                    <table style={{marginLeft: '20%', border: '2px double black', marginTop: '15px', position: 'absolute'}}>
                        <tbody>
                            <tr>
                                <td style={{width: '400px', textAlign: 'center', backgroundColor: '#000000', color: 'white', fontWeight: 'bold', padding: '20px', fontSize: '150%', fontFamily: 'Arial'}}
                                >Fiche de Suivi et de Consignation
                                </td>
                            </tr>
                        </tbody>
	                </table>
                </div>
                <div className="row" style={{marginTop: '120px'}}>
                    <table style={{border: '1px double black', float: 'left'}} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td style={{width: '280px', textAlign: 'right', backgroundColor: '#df051c', color: 'white', padding: '3px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Notice de Câblage et d'Assemblage
                                </td>
                                <td style={{width: '230px', textAlign: 'center', backgroundColor: '#C0C0C0', color: 'black', padding: '3px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >name_cat_num_item_rev_item_NCA_nca
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Procédure de Démarrage & Test
                                </td>
                                <td style={{textAlign: 'center', backgroundColor: '#C0C0C0', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >name_cat_num_item_rev_item_PDT_pdt
                                </td>
                                </tr>
                            <tr>
                                <td style={{textAlign: 'right', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Identifiant BrightLoop
                                </td>
                                <td style={{textAlign: 'center', backgroundColor: '#C0C0C0', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    > name_cat_num_item_rev_item
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style={{position: 'absolute', marginLeft: '650px', marginTop: '0px', border: '2px double black', float: 'right'}} cellSpacing={0}>                        
                        <tbody>
                            <tr>
                                <td style={{width: '150px', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Datecode
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center', backgroundColor: '#C0C0C0', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >datecode
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <table style={{width: '100%', border: '2px double black', marginTop: '260px'}} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td style={{width: '100%', textAlign: 'center', backgroundColor: '#000', color: 'white', fontWeight: 'bold', padding: '10px', fontSize: '150%', fontFamily: 'Arial'}}
                                >Identification des composants BrightLoop et/ou critique
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{width: '100%', marginTop: '30px', border: '2px double black'}} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td style={{width: '50%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Identifiant BrightLoop
                                </td>
                                <td style={{width: '16%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Datecode
                                </td>
                                <td style={{width: '16%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Signature
                                </td>
                            </tr>

                            {/* <?php
                            $query3 = $db->prepare("SELECT * FROM fsc_identification WHERE id_item = :id_item AND id_modele_fk = :id_fsc");
                            $query3->bindParam(':id_item',   $_SESSION["id_item"]);
                            $query3->bindParam(':id_fsc',    $_SESSION["id_fsc"]);
                            $query3->execute();
                            while ($row = $query3->fetch()) {
                            ?> */
                            }
                            <tr>
                                <td style={{textAlign: 'center', color: 'black', backgroundColor: '#C0C0C0', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['identifiant_brightloop']
                                </td>
                                <td style={{textAlign: 'center', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['datecode']
                                </td>
                                <td style={{textAlign: 'center', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['signature']
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* PAGE 2 */}
                <div className="page">
                <div className="row">

                    <table style={{width: '100%', marginTop: '20px', border: '2px double black'}} cellSpacing={0}>                        
                        <tbody>
                            <tr>
                                <td style={{width: '100%', textAlign: 'center', backgroundColor: '#000', color: 'white', fontWeight: 'bold', padding: '10px', fontSize: '150%', fontFamily: 'Arial'}}
                                    >Consignation des résultats
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style={{width: '100%', marginTop: '30px', border: '2px double black'}} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td style={{width: '8%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '2.5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Type §
                                </td>
                                <td style={{width: '6%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '2.5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >n°§
                                </td>
                                <td style={{width: '34%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '2.5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$column1
                                </td>
                                <td style={{width: '22%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '2.5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$column2
                                </td>
                                <td style={{width: '10%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '2.5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$column3
                                </td>
                                <td style={{width: '10%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '2.5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Signature
                                </td>
                            </tr>

                            {/* <?php
                            $query1 = $db->prepare("SELECT * FROM fsc_mesure WHERE id_item = :id_item AND id_modele_fk = :id_fsc AND id_mesure = :id_mesure");
                            $query1->bindParam(':id_item',   $_SESSION["id_item"]);
                            $query1->bindParam(':id_fsc',    $_SESSION["id_fsc"]);
                            $query1->bindParam(':id_mesure', $_SESSION["id_mesure"]);
                            $query1->execute();
                            while ($row = $query1->fetch()) {
                            ?> */}
                            <tr>
                                <td style={{textAlign: 'center', fontSize: '80%', color: 'black', backgroundColor: '#C0C0C0', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['type']
                                </td>
                                <td style={{textAlign: 'center', fontSize: '80%', color: 'black', backgroundColor: '#C0C0C0', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['numero']
                                </td>                                
                                <td style={{textAlign: 'center', fontSize: '70%', color: 'black', backgroundColor: '#C0C0C0', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['controle']
                                </td>                                
                                <td style={{textAlign: 'center', fontSize: '70%', color: 'black', backgroundColor: '#C0C0C0', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['plage']
                                </td>
                                <td style={{textAlign: 'center', fontSize: '70%', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['valeur']
                                </td>
                                <td style={{textAlign: 'center', fontSize: '70%', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['signature']
                                </td>                                                           
                            </tr>
                        </tbody>
                    </table>
                </div> 
                </div> 

                {/* PAGE 3 */}
                <div className="page">
                <div className="row">

                    <table style={{width: '100%', border: '2px double black'}} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td style={{width: '100%', textAlign: 'center', backgroundColor: '#000', color: 'white', fontWeight: 'bold', padding: '10px', fontSize: '150%', fontFamily: 'Arial'}}
                                    >Suivi & Commentaires
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{width: '100%', marginTop: '30px', border: '2px double black', tableLayout: 'fixed'}} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td style={{width: '75%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Commentaire
                                </td>
                                <td style={{width: '12.5%', textAlign: 'center', backgroundColor: '#df051c', color: 'white', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >Signature
                                </td>                                
                            </tr>

                            {/* <?php
                            $query2 = $db->prepare("SELECT * FROM fsc_suivi WHERE id_item = :id_item AND id_modele_fk = :id_fsc AND id_mesure_fk = :id_mesure_fk");
                            $query2->bindParam(':id_item',   $_SESSION["id_item"]);
                            $query2->bindParam(':id_fsc',    $_SESSION["id_fsc"]);
                            $query2->bindParam(':id_mesure_fk', $_SESSION["id_mesure"]);
                            $query2->execute();
                            while ($row = $query2->fetch()) {
                            ?> */}
                            <tr>
                                <td style={{textAlign: 'left', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['commentaire']
                                </td>
                                <td style={{textAlign: 'left', color: 'black', padding: '5px', border: '1px solid black', fontFamily: 'Arial'}}
                                    >$row['signature']
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
                </div> 

            </div>
        );
    }
    
    render() {
        const ref = React.createRef();
        const options = {
            unit: 'mm',
        };

        return (
            <div>
                <div className="control">
                    <ReactToPdf targetRef={ref} filename="PIM.pdf" options={options}>
                        {({toPdf}) => (
                            <button name="pdf" className="new_measure_button" onClick={toPdf}> Télécharger </button>
                        )}
                    </ReactToPdf>
                </div>

                 <div ref={ref}>
                    {this.renderPDF()}
                </div> 
            </div>
        );
    }
}

export default withRouter(GeneratePDF);