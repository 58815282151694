import http from "../../http-common";

class CreateFscDataService {

    createModele() {
        //MODELES
        return http.post('/create_modele') 
        .then(function (response) {
                console.log(response.data);
        })
        .catch(function (error) {
            alert("Erreur ! Création modèle impossible !")
            console.log("Erreur création modèle: ", error.response.data);
        });
    }

    createFSC(id_item, data_fsc) {
        //FSC
        return http.post('/create_fsc/' + id_item, data_fsc) 
        .then(function (response) {
                console.log(response.data);
        })
        .catch(function (error) {
            alert("Erreur dans la FSC ! Vérfiez que tous les champs sont corrects/remplis !")
            console.log("Erreur dans la FSC: ", error.response.data);
        });
    }

    createModeleIdentification(data_identification){
        //MODELE IDENTIFICATION
        return http.post('/create_modele_identification_fsc', data_identification) 
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            alert("Erreur dans l'identification ! Vérfiez que tous les champs sont corrects/remplis !")
            console.log("Erreur dans l'identification: ", error.response.data);
        });
    }

    createModeleMesure(data_modele){
        //MODELE MESURE
        return http.post('/create_modele_mesure_fsc', data_modele) 
        .then(function (response) {
            console.log(response.data);
            alert("FSC créée !")
        })
        .catch(function (error) {
            alert("Erreur dans le modèle ! Vérfiez que tous les champs sont corrects/remplis !")
            console.log("Erreur dans le modèle: ", error.response.data);
        });   
    }

    // createFSCTest(data_fsc, id_item, data_identification, data_modele) {
        
    //     try {
    //         //FSC avec identification + modele pour rollback dans la bdd
    //         http.post('/create_fsc/' + id_item, data_fsc, data_identification, data_modele) 
    //         .then(function (response) {
    //              console.log(response.data);
    //         })
    //         .catch(function (error) {
    //             alert("Erreur ! Vérfiez que tous les champs sont corrects/remplis !")
    //             return console.log("Erreur : ", error.response.data);
    //         });

    //         return 'Création FSC terminée !'
    //     } catch (error) {
    //         return console.log(error)
    //     }
    // }

}

export default new CreateFscDataService();