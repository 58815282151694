import React from "react";
import LoginForm from "../components/LoginForm";

const login = () => {

    return(
        <LoginForm/>
    )
}

export default login;