import React from 'react';
import GeneratePDF from '../../components/FSC/GeneratePDF';
import Navbar from '../../components/Navbar';

const generate_pdf = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <GeneratePDF/>
        </div>
    </div>
    );
};

export default generate_pdf;