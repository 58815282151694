import React from 'react';
import MenuFSC from '../../components/FSC/MenuFSC';
import Navbar from '../../components/Navbar';

const menu_fsc = () => {
    return (
        <div>
            <Navbar/>
            <MenuFSC/>
        </div>
    );
};

export default menu_fsc;