import React from 'react';
import { NavLink } from "react-router-dom";

class LoginNavbar extends React.Component {
    render() {
        return(
            <nav className="LoginNavbarItems">
                <h1 className="Title"> BrightLoop PIM </h1>
                <div className="login-link">
                    <NavLink exact to ="/login" activeClassName="login-nav-active"> Login </NavLink>
                </div>
            </nav>
        );
    };
};

export default LoginNavbar;