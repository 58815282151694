import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
import { withRouter } from 'react-router-dom'
import LoadingData from '../LoadingData';
import 'react-tabulator/lib/css/tabulator.css';
import FscDataService from '../../services/data/fsc.service'
import CreateFscDataService from '../../services/data/createfsc.service'
import SendingData from '../SendingData';
import BackToMenu from '../BackToMenu';

    /* FSC */ 
    const fsc = [                  
        {title:"Identifiant BrightLoop", field:"id_brightloop",  width:250, editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},
        {title:"Révision FSC",           field:"rev_fsc",        width:130, editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},
        {title:"NCA",                    field:"rev_nca",        width:95,  editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},
        {title:"PDT",                    field:"rev_pdt",        width:95,  editor:"input",  editor:true, hozAlign:"center", headerHozAlign:"center"},  
    ];


    /* MODELE_IDENTIFICATION FSC */ 
    const identification_fsc = [
        {
            formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell){
                if(window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) cell.getRow().delete()
            }
        },   
        {title:"Identification",  field:"identifiant_brightloop",   width:370,  editor:true, hozAlign:"center", headerHozAlign:"center"},
        ];


    /* MODELE_MESURE FSC */
    const type_controle = ["nOK/OK", "Vmin/Vmax", "Vm + %", "État"]  
    const modele_fsc = [
        {
            formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell){
                if(window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) cell.getRow().delete()
            }
        },   
        {title:"Type §",          field:"type_para",         width:95,  editor:"select", editorParams:{values:["NCA", "PDT"]}, hozAlign:"center", headerHozAlign:"center"},
        {title:"Numéro §",        field:"n_para",            width:110, editor:true, headerHozAlign:"center"},
        {title:"Contrôle",        field:"controle",          width:150, editor:true, headerHozAlign:"center", hozAlign:"center"},
        {title:"Type contrôle", field:"type_controle",  width:150,  editor:"select", editorParams:{values:type_controle}, hozAlign:"center", headerHozAlign:"center",
        cellEdited:function(cell){
                //const titleColumn = cell.getValue();
                //modele_fsc.addColumn({title:titleColumn, field:"age"}, true, "name");
            },
        },        
        {title:"Valeur contrôle", field:"valeur_controle",   width:150, editor:true, headerHozAlign:"center", hozAlign:"center"}
    ];

class CreateMeasure extends Component {

    constructor(){
        super();
        this.state = {
            id_item: '',
            id_modele_mesure: '',
            id_modele_identification: '',
            id_brightloop: '',
            rev_fsc: '',
            rev_nca: '',
            rev_pdt: '',
            fsc_data:[],
            fsc_identification_data:[],
            fsc_measure_data:[],
            LoadingFSC: '',
            LoadingModeleMesure: '',
            LoadingModeleIdentification: ''
        };

        this.fsc = React.createRef();
        this.identification = React.createRef();
        this.modele = React.createRef();

        this.handleClick = this.handleClick.bind(this);

        this.AddRowModele= this.AddRowModele.bind(this);
        this.AddRowIdentification= this.AddRowIdentification.bind(this);
    }

    //Fonctions identification
    AddRowIdentification() {
        this.identification.current.table.addRow(false); //true adds to top
    }

    //Fonctions modele
    AddRowModele() {
        this.modele.current.table.addRow(false);
    }

    componentDidMount() {
        this.setState({
            LoadingFSC: true,
            LoadingIdentification: true,
            LoadingFSC: true,
            SendingFSC:false,
            produit: this.props.match.params.produit,
            id_fsc: this.props.match.params.fsc
        },  function () {
                console.log("ID item : ", this.state.produit)
                console.log("ID FSC : ", this.state.id_fsc)
                this.LoadFSC().then(
                    exec => this.LoadModeleMesure().then(
                    exec => this.LoadModeleIdentification().then(
                    )))
            });
    }
    
    async LoadFSC(){
        const prom = FscDataService.LoadFSC(this.state.id_fsc).then(res => {
            this.setState({ 
                fsc_data: res.data,
                id_brightloop: res.data[0].id_brightloop,
                rev_fsc: res.data[0].rev_fsc,
                rev_nca: res.data[0].rev_nca,
                rev_pdt: res.data[0].rev_pdt 
            }, 
            () => console.log("[FSC] Données reçues: ", this.state.fsc_data),
                this.setState({LoadingFSC: false}
                )                    
            )        
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadFSC terminé !'
    }

    /* CHARGEMENT */
    async LoadModeleMesure(){    
        const prom = FscDataService.LoadModeleMesure(this.state.id_fsc).then(res => {
            this.setState({ 
                fsc_modele_mesure_data: res.data 
            }, 
            () => console.log("[MODELE_MESURE] Données reçues: ", this.state.fsc_modele_mesure_data),
                this.setState({ LoadingModeleMesure: false}, 
                //() => console.log("ModeleMesureLoaded: ", this.state.ModeleMesureLoaded),
                )                
            )
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadModeleMesure terminé !'
    }

    async LoadModeleIdentification(){    
        const prom = FscDataService.LoadModeleIdentification(this.state.id_fsc).then(res => {
            this.setState({ 
                fsc_modele_identification_data: res.data 
            }, 
            () => console.log("[MODELE_IDENTIFICATION] Données reçues: ", this.state.fsc_modele_identification_data),
                this.setState({ LoadingModeleIdentification: false}, 
                //() => console.log("ModeleIdentificationLoaded: ", this.state.ModeleIdentificationLoaded),
                )                
            )
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'LoadModeleIdentification terminé !'
    }

    /* CREATION */ 
    async CreateModele() {

        console.log("[MODELES] Création d'un modèle...")

        const prom = CreateFscDataService.createModele()
        
        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateModele terminé !'
    }

    async CreateFSC() {

        var data_fsc = this.fsc.current.table.getData();
        var id_item = this.state.produit;

        console.log("[FSC] Données envoyées au serveur : ", data_fsc,", ",id_item)

        const prom = CreateFscDataService.createFSC(id_item, data_fsc)
        
        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateFSC terminé !'
    }

    async CreateModeleIdentification() {

        var data_identification = this.identification.current.table.getData();

        console.log("[MODELE_IDENTIFICATION] Données envoyées au serveur : ", data_identification)

        const prom = CreateFscDataService.createModeleIdentification(data_identification)

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateModeleIdentification terminé !'
    }

    async CreateModeleMesure() {

        var data_modele = this.modele.current.table.getData();

        console.log("[MODELE_MESURE] Données envoyées au serveur : ", data_modele)

        const prom = CreateFscDataService.createModeleMesure(data_modele).then(res => {
            this.setState({ 
                SendingFSC: false 
            }, 
            () => console.log("SendingFSC: ", this.state.SendingFSC),
            this.props.history.push('/fsc/menu_fsc/' + this.state.produit)                   
            ) 
        })

        const result = await Promise.all([ prom ]); // wait for both promise to complete
        return 'CreateModeleMesure terminé !'
    }

    handleClick(event){
        event.preventDefault();
        this.setState({ 
            SendingFSC: true, 
        }, 
        () => console.log("Sending FSC: ", this.state.SendingFSC),
        this.CreateModele().then(
            exec => this.CreateFSC().then(
            exec => this.CreateModeleIdentification().then(
            exec => this.CreateModeleMesure()
            ))
        ),
        )
    }

    renderDataSent() {
        if(this.state.SendingFSC) {
          return (
            <div>
                <SendingData/>
            </div>
          );
        }
    }

    render() {
        return (
            !this.state.LoadingFSC && !this.state.LoadingModeleIdentification && !this.state.LoadingModeleMesure
            
            ?

            <div>

                {this.renderDataSent()}

                <BackToMenu/>

                <div className="Titre">
                    <h1>Réviser une FSC</h1>
                </div>

                <div className="Infos">
                    <div>
                        <h2> {this.state.id_brightloop}</h2>
                        <h3> Fiche de suivi et Consignation : {this.state.id_brightloop}_FSC_{this.state.rev_fsc} </h3>
                        <h3> Notice de Câblage & Assemblage : {this.state.id_brightloop}_NCA_{this.state.rev_nca} </h3>
                        <h3> Notice de Câblage & Assemblage : {this.state.id_brightloop}_PDT_{this.state.rev_pdt} </h3>
                    </div> 
                </div>

                <div className="control">
                    <button name="pdf"  className="new_measure_button" onClick={this.handleClick} >Réviser</button>
                </div>  
        
                <div className="Table">
                    <ReactTabulator
                    ref={this.fsc}                    
                    columns={fsc}
                    data={this.state.fsc_data}
                    tooltips={true}
                    layout={"fitColumns"}
                    history={true}               
                    resizableRows={false}               
                    headerSort={false}
                    headerHozAlign={"center"}
                    />

                </div>

                <div className="control">
                    <button name="add_row" onClick={this.AddRowIdentification} className="control_button"    >Add row</button>
                </div> 
                <div className="Table">
                    <ReactTabulator
                    ref={this.identification}
                    columns={identification_fsc}
                    data={this.state.fsc_modele_identification_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    headerHozAlign={"center"}
                    />
                </div>

                <div className="control">
                    <button name="add_row" onClick={this.AddRowModele} className="control_button" >Add row</button>
                </div>  
                <div className="Table">
                    <ReactTabulator
                    ref={this.modele}
                    columns={modele_fsc}
                    data={this.state.fsc_modele_mesure_data}
                    tooltips={true}
                    layout={"fitData"}
                    history={true}               
                    resizableRows={false}              
                    headerSort={false}
                    headerHozAlign={"center"}
                    />
                </div>
            </div>

            :
            <div>
                <LoadingData/>
            </div>
        );
    }
}
export default withRouter(CreateMeasure);