import React from 'react';
import ReviseFSC from '../../components/FSC/ReviseFSC';
import Navbar from '../../components/Navbar';

const revise_fsc = () => {
    return (
        <div>
            <div>
                <Navbar/>
            </div>
            <div>
                <ReviseFSC/>
            </div>
        </div>
    );
};

export default revise_fsc;