import React from 'react';
import CreateFSC from '../../components/FSC/CreateFSC';
import Navbar from '../../components/Navbar';

const create_fsc = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <CreateFSC/>
        </div>
    </div>
    );
};

export default create_fsc;