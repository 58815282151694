import React from 'react';
import SuiviFSC from '../../components/FSC/SuiviFSC';
import Navbar from '../../components/Navbar';

const suivi_fsc = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <SuiviFSC/>
        </div>
    </div>
    );
};

export default suivi_fsc;