import React, {useEffect, useState} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import FscDataService from '../../services/data/fsc.service';
import LoadingData from '../LoadingData';

const SelectFscRevise = () => {
    const history = useHistory();

    const [ListId, setListId] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [LoadingFSC, setLoadingFSC] = useState(true);
    const { produit } = useParams(); //produit = id_item

    function getChoixId(e) {
        setSelectedId(e.target.value)
    }

    useEffect(()=>{
        FscDataService.getFSCbyItem(produit)
        .then(response => {
            console.log("[FSC] Données reçues : ", response.data)
            setListId(response.data)
            setLoadingFSC(false)
            if (response.data.length == 0) {
                alert('Aucune FSC disponible pour ce produit !')
                history.goBack()
            }
        })
    }, [])

    function handleSubmit(e) {
        //alert('Modele ID selected: ' + selectedId)
        e.preventDefault()
        history.push('/fsc/revise_fsc/id_item=' + produit + '/id_fsc=' + selectedId)
    }

    return (
        !LoadingFSC
        ?
        <div>
            <div className="double-dropdown" style={{marginTop:'25px'}}>                
                <select className="choixtype" id="choix_fsc" onChange={getChoixId}>
                    <option value="">-- Choix de la FSC --</option>
                    {Object
                    .keys(ListId)
                    .map((item, i) => 
                    <option key={i} value={ListId[item].id}
                    >{ListId[item].id_brightloop}_FSC_{ListId[item].rev_fsc}</option>)
                    }       
                </select> 
            </div>

            <div className="double-dropdown-button">
                <form method="get" name="envoi" onSubmit={handleSubmit}>
                    <button type="submit" className="button"> Sélectionner </button>
                </form>
            </div>
        </div>
        :
        <div>
            <LoadingData/>
        </div>
   );
};

export default withRouter(SelectFscRevise);