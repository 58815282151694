import React from 'react';
import SelectDatecodeMeasure from '../../components/FSC/SelectDatecodeMeasure';
import Navbar from '../../components/Navbar';

const select_datecode_measure = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <SelectDatecodeMeasure/>
        </div>
    </div>
    );
};

export default select_datecode_measure;