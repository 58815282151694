import React from 'react';
import CreateDatecode from '../../components/FSC/CreateDatecode';
import Navbar from '../../components/Navbar';

const consult_fsc = () => {
    return (
    <div>
        <div>
            <Navbar/>
        </div>
        <div>
            <CreateDatecode/>
        </div>
    </div>
    );
};

export default consult_fsc;